import { Language } from '@evasys/globals/evainsights/constants/types';
import { isEnumValue } from '@evasys/globals/evainsights/typeguards/common';

export const getUserLanguageCode = (activeLang: string): Language => {
	const userLanguageCode = activeLang.substring(0, 2);
	if (!isEnumValue(userLanguageCode, Language)) {
		throw new Error(`Language code ${activeLang} is not supported`);
	}

	return userLanguageCode;
};

export const getContextLanguageCode = (activeLang: string) => {
	return activeLang.substring(3);
};
