import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { fitTexts } from '@evasys/evainsights/shared/util';
import { TextMeasurementService } from '@evasys/shared/util';

@Component({
	selector: 'evainsights-typeahead-filter-result',
	templateUrl: './typeahead-filter-result.component.html',
})
export class TypeaheadFilterResultComponent implements OnChanges, AfterViewInit, OnDestroy {
	static _dotWidth?: number;

	@Input()
	contextInfos?: (string | number)[];
	@Input()
	text!: string;
	@Input()
	term!: string;

	contextInfoNaturalWidths?: number[];
	contextInfoTruncatedWidths?: number[];

	fontClass = 'fs-5';
	contextInfoElementClass = `${this.fontClass} d-inline-block c-nowrap`;
	dotClass = `${this.fontClass} px-2 d-inline-block`;
	dotContent = '•';

	resizeObserver?: ResizeObserver;
	private animationFrameId?: number;

	@ViewChild('container') containerElement!: ElementRef;

	constructor(private cd: ChangeDetectorRef, private textMeasurementService: TextMeasurementService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['contextInfos']) {
			this.updateContextInfoNaturalWidths();
		}
	}

	ngAfterViewInit() {
		this.resizeObserver = new ResizeObserver(() => {
			if (this.animationFrameId) {
				cancelAnimationFrame(this.animationFrameId);
			}
			this.animationFrameId = requestAnimationFrame(() => {
				this.updateContextInfoTruncatedWidths.bind(this);
			});
		});
		this.resizeObserver.observe(this.containerElement.nativeElement);
	}

	ngOnDestroy() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	}

	isContextInfoTruncated = (index: number) => {
		return (
			this.contextInfoNaturalWidths &&
			this.contextInfoTruncatedWidths &&
			this.contextInfoNaturalWidths[index] > this.contextInfoTruncatedWidths[index]
		);
	};

	updateContextInfoNaturalWidths() {
		if (this.contextInfos && this.contextInfos.length > 0) {
			this.contextInfoNaturalWidths = this.contextInfos.map((v) =>
				this.textMeasurementService.getOffsetWidth(v.toString(), this.contextInfoElementClass)
			);
		} else {
			this.contextInfoNaturalWidths = undefined;
		}
	}

	updateContextInfoTruncatedWidths() {
		if (this.contextInfoNaturalWidths) {
			const totalDotsWidth = this.getDotWidth() * (this.contextInfoNaturalWidths.length - 1);
			const availableWidth = this.containerElement.nativeElement.offsetWidth - totalDotsWidth;
			this.contextInfoTruncatedWidths = fitTexts(this.contextInfoNaturalWidths, availableWidth);
		} else {
			this.contextInfoTruncatedWidths = undefined;
		}

		this.cd.detectChanges();
	}

	getDotWidth() {
		if (TypeaheadFilterResultComponent._dotWidth === undefined) {
			TypeaheadFilterResultComponent._dotWidth = this.textMeasurementService.getOffsetWidth(
				this.dotContent,
				this.dotClass
			);
		}

		return TypeaheadFilterResultComponent._dotWidth;
	}
}
