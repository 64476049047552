import { Validators } from '@angular/forms';
import { phoneValidator } from './phone.validator';
import { equalValidator } from './equal.validator';
import { emailValidator } from './email.validator';
import { securePasswordValidator } from './secure-password.validator';

export class Validations extends Validators {
	static phone = phoneValidator();
	static equal = (firstControl: string, secondControl: string) => equalValidator(firstControl, secondControl);
	static override email = emailValidator();
	static securePassword = securePasswordValidator();
}
