import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameViaIdInListPipe } from './pipes/name-via-id-in-list.pipe';
import { AppCheckerService } from './appInit/services/app-checker.service';
import { HttpClientModule } from '@angular/common/http';
import { UserRightsPipe } from './pipes/user-rights.pipe';
import { UserRightsDesignationPipe } from './pipes/user-rights-designation.pipe';
import { CanAccessPipe } from './pipes/can-access.pipe';
import { ScreenService } from './screen/services/screen.service';
import { ScreenDirective } from './screen/directives/screen.directive';
import { CommaSeparatedPipe } from './pipes/comma-separated.pipe';
import { NamesByIdsPipe } from './pipes/names-by-ids.pipe';
import { UnescapePipe } from './pipes/unescape.pipe';
import { ToMapPipe } from './pipes/to-map.pipe';
import { EntitiesToIdsPipe } from './pipes/entities-to-ids.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { HasPipe } from './pipes/has.pipe';
import { IdsToEntitiesPipe } from './pipes/ids-to-entities.pipe';
import { SplicePipe } from './pipes/splice.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';
import { SameWidthDirective } from './directives/same-width/same-width.directive';
import { ReducePipe } from './pipes/reduce.pipe';
import { ToObservablePipe } from './pipes/to-observable.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { LazyLoadModule } from './directives/lazy-load/lazy-load.module';
import { InvokePipe } from './pipes/invoke.pipe';
import { IfContentModule } from './directives/if-content/if-content.module';
import { ModifyModule } from './pipes/modify/modify.module';
import { RegulationClickModule } from './directives/regulation-click/regulation-click.module';
import { HasOnlyModule } from './pipes/has-only/has-only.module';
import { SortModule } from './pipes/sort/sort.module';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		IfContentModule,
		ModifyModule,
		RegulationClickModule,
		HasOnlyModule,
		SortModule,
	],
	declarations: [
		NameViaIdInListPipe,
		UserRightsPipe,
		UserRightsDesignationPipe,
		CanAccessPipe,
		ScreenDirective,
		CommaSeparatedPipe,
		NamesByIdsPipe,
		FilterPipe,
		UnescapePipe,
		ToMapPipe,
		EntitiesToIdsPipe,
		ToMapPipe,
		HasPipe,
		IdsToEntitiesPipe,
		SplicePipe,
		ToStringPipe,
		SameWidthDirective,
		ReducePipe,
		ToObservablePipe,
		JoinPipe,
		InvokePipe,
	],
	exports: [
		NameViaIdInListPipe,
		UserRightsPipe,
		UserRightsDesignationPipe,
		CanAccessPipe,
		ScreenDirective,
		CommaSeparatedPipe,
		NamesByIdsPipe,
		FilterPipe,
		ToMapPipe,
		HasPipe,
		IdsToEntitiesPipe,
		SplicePipe,
		SameWidthDirective,
		UnescapePipe,
		ToMapPipe,
		EntitiesToIdsPipe,
		ToStringPipe,
		ReducePipe,
		ToObservablePipe,
		JoinPipe,
		InvokePipe,

		LazyLoadModule,
		ModifyModule,
		RegulationClickModule,
		IfContentModule,
		HasOnlyModule,
		SortModule,
	],
	providers: [AppCheckerService, ScreenService],
})
export class SharedUtilModule {}
