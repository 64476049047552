import { Item, ItemTypeId } from '../models/item/item.model';
import { ItemOption } from '../models/item/item-value-position.model';

const getTypeIdPredicate =
	(...trueTypeIds: ItemTypeId[]) =>
	(item: Item) =>
		trueTypeIds.includes(item.typeId);

export const isScalaItem = getTypeIdPredicate(ItemTypeId.SCALA, ItemTypeId.OPTIONAL_SCALA);
export const isOpenQuestionItem = getTypeIdPredicate(ItemTypeId.OPEN_QUESTION, ItemTypeId.OPTIONAL_OPEN_QUESTION);
export const isSingleChoiceItem = getTypeIdPredicate(ItemTypeId.SINGLE_CHOICE, ItemTypeId.OPTIONAL_SINGLE_CHOICE);
export const isMultipleChoiceItem = getTypeIdPredicate(ItemTypeId.MULTIPLE_CHOICE, ItemTypeId.OPTIONAL_MULTIPLE_CHOICE);

export const hasAbstention = (item: Item) => item.itemOptions.some(isAbstention);
export const hasAnyItemAbstentions = (items: Item[] | undefined): boolean => {
	return items?.some((item) => hasAbstention(item)) ?? false;
};

export const isAbstentionPosition = (position: number) => position === 0;
export const isAbstention = (itemOption: ItemOption) => isAbstentionPosition(itemOption.position);

export const isNotAbstention = (itemOption: ItemOption) => !isAbstention(itemOption);

export const sortItemOptionsByPosition = (a: ItemOption, b: ItemOption) =>
	Math.min(a.position, b.position) < 1 ? b.position - a.position : a.position - b.position;
