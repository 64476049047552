import { inject, Injectable } from '@angular/core';
import {
	FormMultiLang,
	FormMultiLangEntry,
	GeneralMultiLang,
	GeneralMultiLangEntry,
	isFormMultiLang,
	isReportMultiLang,
	isSystemMultiLang,
	ReportMultiLang,
	ReportMultiLangEntry,
	SystemMultiLang,
	SystemMultiLangEntry,
} from '@evasys/globals/evainsights/models/common/multi-lang';
import { findSystemMultiLangEntry } from './select';

import { map, Observable, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { getUserLanguageCode } from './language-util';
import { Language } from '@evasys/globals/evainsights/constants/types';

@Injectable()
export abstract class MultiLangService {
	translocoService = inject(TranslocoService);
	userLang$ = this.translocoService.langChanges$.pipe(map(getUserLanguageCode));

	translate<T>(multiLang: GeneralMultiLang<T> | null | undefined): Observable<T | undefined>;
	translate<T>(multiLang: GeneralMultiLang<T> | null | undefined, fallback: T): Observable<T>;
	translate<T>(multiLang: GeneralMultiLang<T> | null | undefined, ...fallback: [T] | []): Observable<T | undefined> {
		if (multiLang === null || multiLang === undefined) {
			return of(fallback.length > 0 ? fallback[0] : undefined);
		}

		return this.findGeneralMultiLangEntry(multiLang).pipe(
			map((entry) => {
				if (entry !== undefined) {
					return entry.value;
				} else if (fallback.length > 0) {
					return fallback[0];
				} else {
					return undefined;
				}
			})
		);
	}

	private findGeneralMultiLangEntry<T>(
		multiLang: GeneralMultiLang<T>
	): Observable<GeneralMultiLangEntry<T> | undefined> {
		if (isSystemMultiLang(multiLang)) {
			return this.findSystemMultiLangEntry(multiLang);
		} else if (isFormMultiLang(multiLang)) {
			return this.findFormMultiLangEntry(multiLang);
		} else if (isReportMultiLang(multiLang)) {
			return this.findReportMultiLangEntry(multiLang);
		}

		throw Error('Could not determine multilang type for value ' + multiLang);
	}

	protected findSystemMultiLangEntry<T>(
		systemMultiLang: SystemMultiLang<T>
	): Observable<SystemMultiLangEntry<T> | undefined> {
		return this.fromUserLang((userLang) => findSystemMultiLangEntry(systemMultiLang, userLang));
	}

	protected abstract findFormMultiLangEntry<T>(
		formMultiLang: FormMultiLang<T>
	): Observable<FormMultiLangEntry<T> | undefined>;

	protected abstract findReportMultiLangEntry<T>(
		reportMultiLang: ReportMultiLang<T>
	): Observable<ReportMultiLangEntry<T> | undefined>;

	protected fromUserLang<T>(mapFn: (userLang: Language) => T): Observable<T> {
		return this.userLang$.pipe(map(mapFn));
	}
}
